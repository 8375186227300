import React from 'react';
import { ChakraProvider, HStack, useColorModeValue, theme, Spacer, VStack, Heading} from '@chakra-ui/react'
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';





function App() {

  return (
    <ChakraProvider theme={theme}>
     <VStack>
      <HStack w={'full'} h={100} p={5} boxShadow={'2xl'} bgColor={useColorModeValue('cyan.900', 'gray.50')}>
        <Logo />
        <Spacer />
        <ColorModeSwitcher />
      </HStack>
      <VStack w={'full'} h={100} p={5} borderRadius='3xl' boxShadow={'2xl'} bgColor={useColorModeValue('cyan.900', 'gray.50')}>
      <Heading 
            size={"3xl"} 
            fontWeight={900} 
            bgGradient='linear(to-r, orange.300, yellow.400, orange.200)'
            bgClip="text"
            >
                DigiRupi Buyback coming soon!
            </Heading>
      </VStack>
     </VStack>
    </ChakraProvider>
  );
  }

export default App;
