import { Center, Divider, Heading, HStack, } from '@chakra-ui/react'
import React from 'react'

export const Logo = () => {
  return (
    <HStack>
       <Center boxSize={10} borderWidth="thick" borderRadius={"full"} ></Center>
        <Center h={10}>
            <Divider orientation='vertical' />         
        </Center>
        <Heading 
            size={"md"} 
            fontWeight={900} 
            bgGradient='linear(to-r, orange.300, yellow.400, orange.200)'
            bgClip="text"
            >
                DigiRupi
            </Heading>
    </HStack>
  )
}
